import {ScheduleService} from '../schedule.service';
import {Component, Input, OnInit} from '@angular/core';
import {Moment} from 'moment';
import * as moment from 'moment';
import {AuthenticationService} from '../../login/auth.service';

const PARKING_SPOTS = 6;
const PARK_LIMIT = 7; // Days available in future

@Component({
  selector: 'app-day-comp',
  templateUrl: './day.component.html',
  styleUrls: ['./day.component.scss'],
})
export class DayComponent implements OnInit {

  @Input() inputData;

  constructor(private schedule: ScheduleService, private auth: AuthenticationService) {
  }

  public showLoading = false;
  public inputDay: Moment;
  public allocatedSorted;
  public subscribers;
  public othersCount: number;
  public subscribeBtnStateForParking = true;
  public subscribeBtnStateForCancelParking = false;
  public isParkingPossible = false;
  private now = moment();
  private today: Moment;
  private parkLimit: Moment;
  public currentUserId: any;

  ngOnInit() {
    this.currentUserId = this.auth.getCurrentUser()?.['id'];

    this.inputDay = this.inputData.day;
    this.inputDay.set({hour: 0, minute: 0, second: 0, millisecond: 0});
    this.today = this.now.clone().set({hour: 0, minute: 0, second: 0, millisecond: 0});

    this.parkLimit = this.now.clone().add(PARK_LIMIT, 'days');
    this.isParkingPossible = this.inputDay.isBetween(this.today, this.parkLimit, undefined, '[]');

    this.getSubscribers(this.inputData);
  }

  public shouldShowLoader(): Boolean {
    return this.showLoading;
  }

  private getSubscribers(input): void {
    this.subscribers = input;
    this.othersCount = this.subscribers.waitingListSize;

    // Check if current user is in allocated or in others list
    const isAllocated = this.subscribers.allocated.find((item) => {
      return item.user['id'] === this.currentUserId;
    });
    const isOthers = this.subscribers.isCurrentUserInWaitingList;

    if (isAllocated || isOthers) {
      this.subscribeBtnStateForParking = false;
      this.subscribeBtnStateForCancelParking = true;
    } else {
      this.subscribeBtnStateForParking = true;
      this.subscribeBtnStateForCancelParking = false;
    }

    for (let i = 0; i < PARKING_SPOTS; i++) {
      if (!this.subscribers.allocated[i]) {
        this.subscribers.allocated[i] = {user: {}};
      }
    }

    this.allocatedSorted = this.subscribers.allocated;

    this.showLoading = false;
  }

  subscribeBtnToggle(parkOrCancel) {
    if (!this.isParkingPossible) {
      return;
    }

    this.showLoading = true;

    //correcting date per timezone offset
    const date = new Date(moment(this.inputDay).startOf('day').toISOString());

    const offset = date.getTimezoneOffset();
    if (offset < 0) {
      date.setHours(12, 0, 0);
    }

    this.schedule
      .parkToggle({
        operation: parkOrCancel > 0 ? 'park' : 'cancel',
        date: date,
      })
      .subscribe(
        (data: any) => {
          this.subscribeBtnStateForCancelParking = this.subscribeBtnStateForParking;
          this.subscribeBtnStateForParking = !this.subscribeBtnStateForParking;

          this.getSubscribers({
            day: moment(data.date),
            allocated: data.allocated,
            isCurrentUserInWaitingList: data.isCurrentUserInWaitingList,
            waitingListSize: data.waitingListSize
          });
        },
        () => {
          this.showLoading = false;
        }
      );
  }
}
