import { Component } from '@angular/core';
import * as moment from 'moment';
import { ScheduleService } from './schedule.service';

@Component({
  selector: 'app-week-wrapper',
  templateUrl: './week-view.component.html',
  styleUrls: ['./week-view.component.scss']
})
export class WeekViewComponent {
  public weekNumber: Number;
  public weekDays: any;
  public showLoading = false;
  constructor(private schedule: ScheduleService) {}

  showWeek(dataFromChild: any) {
    this.weekNumber = dataFromChild.weekNumber;
    const year = dataFromChild.year;

    this.showLoading = true;

    this.schedule.getWeekSchedule(year, this.weekNumber).subscribe((result) => {
      this.weekDays = dataFromChild.weekDays.map((day: moment.MomentInput) => {
        const myResultDay = result.filter((res) => {
          return moment(res.date).date() === moment(day).date();
        });

        const output = {
          day: day,
          allocated: myResultDay[0] ? myResultDay[0].allocated : [],
          waitingListSize: myResultDay[0]?.waitingListSize,
          isCurrentUserInWaitingList: myResultDay[0]?.isCurrentUserInWaitingList
        };

        return output;
      });
      this.showLoading = false;
    });
  }
}
